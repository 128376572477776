import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.from('.logo', {
  duration: 1,
  opacity: 0,
  y: 35,
});

gsap.from('.fadeIn', {
    scrollTrigger: {
      trigger: '.intro',
      start: 'bottom bottom',
      end: '+=300',
      markers: false,
      toggleActions: 'play none none reverse',
    },
    duration: 1,
    opacity: 0,
});

// gsap.from('.hero', {
//   scrollTrigger: {
//     trigger: '.hero',
//     start: 'top bottom',
//     end: '+=500',
//     scrub: true,
//     markers: false,
//     toggleActions: 'restart none none none',
//   },
//   duration: 1,
//   opacity: 0,
// });
//
// gsap.from('.oorzaak', {
//   scrollTrigger: {
//     trigger: '.oorzaak',
//     start: 'top bottom',
//     end: '+=350',
//     scrub: true,
//     markers: false,
//     toggleActions: 'restart none none none',
//   },
//   duration: 1,
//   opacity: 0,
//   x: 100,
// });




$(document).foundation();
